import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faCartShopping,
  faHeart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

export default function Footer() {
  return (
    <>
      <Navbar
        expand="lg"
        sticky="bottom"
        style={{ backgroundColor: "#5A065E" }}
      >
        <Container>
          <Nav
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 1.2rem 0 1.2rem",
            }}
          >
            <Nav.Link>
              <NavLink to="/">
                <div
                  style={{
                    color: "#B2A4B3",
                    border: "2px solid #B2A4B3",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    width: "3rem",
                    height: "3rem",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faHouse}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/product">
                <div
                  style={{
                    color: "#B2A4B3",
                    border: "2px solid #B2A4B3",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    width: "3rem",
                    height: "3rem",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/cart">
                <div
                  style={{
                    color: "#B2A4B3",
                    border: "2px solid #B2A4B3",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    width: "3rem",
                    height: "3rem",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/">
                <div
                  style={{
                    color: "#B2A4B3",
                    border: "2px solid #B2A4B3",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    width: "3rem",
                    height: "3rem",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/">
                <div
                  style={{
                    color: "#B2A4B3",
                    border: "2px solid #B2A4B3",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    width: "3rem",
                    height: "3rem",
                    position: "relative",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                    }}
                  />
                </div>
              </NavLink>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      
    </>
  );
}
