import {
  faShareNodes,
  faSquareCaretDown,
  faSquareCaretUp,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import logo from "../../../image/a648d3f55dd077e61140092e4ac296d6.png";
import img1 from "../../../image/IMG_4137 1.png";

export default function ProductPage() {
  const [count, setCount] = useState(0);
  const incrementCount = () => {
    setCount(count + 1);
  };
  const DecrementCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  return (
    <>
      <div style={{ width: "100%" }}>
        <Card
          style={{
            backgroundColor: "#5A065E",
            borderRadius: "0 0 3rem 3rem",
            padding: "1.5rem 0 5rem 0",
          }}
        >
          <Card style={{ margin: "0 Auto", padding: "0.5rem 1.5rem" }}>
            <img src={logo} alt="" style={{ maxHeight: "64px" }} />
          </Card>
        </Card>
        <Card className="carousel-cardproduct">
          <div className="carousel-cardproductimage">
            <img
              className="d-block w-100 slider-image "
              src={img1}
              alt="First slide"
            />
          </div>
          <div className="percentage-off-label">
            <div>10% OFF</div>
          </div>
        </Card>
      </div>
      <Container>
        <Row>
          <Col style={{ padding: "0", color: "#2B4A2A" }}>
            <Card.Body className="ProductpageRatting">
              <Card.Text>
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </Card.Text>
            </Card.Body>
          </Col>
          <Col style={{ padding: "0", color: "#2B4A2A" }}>
            <Card.Body className="ProductpageRatting">
              <Card.Text style={{ textDecorationLine: "underline" }}>
                25 Ratings
              </Card.Text>
            </Card.Body>
          </Col>
          <Col style={{ padding: "0", color: "#2B4A2A" }}>
            <Card.Body className="ProductpageRatting">
              <Card.Text style={{ textDecorationLine: "underline" }}>
                5 Reviews
              </Card.Text>
            </Card.Body>
          </Col>
          <Col style={{ padding: "0", color: "#2B4A2A" }}>
            <Card.Body className="ProductpageRatting">
              <Card.Text>
                <FontAwesomeIcon icon={faShareNodes} /> Share
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
        <Row xs={2} md={2} style={{ paddingTop: "1.3rem" }}>
          <Col>
            <Card style={{ border: "0" }}>
              <Card.Body style={{ textAlign: "left", padding: "0" }}>
                <Card.Title
                  style={{
                    fontSize: "2rem",
                    color: "#2B4A2A",
                    fontWeight: "700",
                  }}
                >
                  Rs: 98.10
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ border: "0" }}>
              <Card.Body style={{ padding: "0",textAlign:"right",display: "flex",justifyContent:"flex-end" }}>
                <Card.Text
                  style={{
                    color: "#2B4A2A",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent:"flex-end"
                  }}
                >
                  <div>Quantity</div>
                  <div style={{ display: "flex",justifyContent:"flex-end" }}>
                  <input
                    type="number"
                    value={count}
                    style={{ width: "65%", marginLeft: "0.8rem" }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FontAwesomeIcon
                      icon={faSquareCaretUp}
                      onClick={incrementCount}
                    />
                    <FontAwesomeIcon
                      icon={faSquareCaretDown}
                      onClick={DecrementCount}
                    />
                  </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ border: "0" }}>
              <Card.Body style={{ padding: "0" }}>
                <Card.Text style={{ color: "#2B4A2A", display: "flex" }}>
                  M.R.P.:
                  <Card.Text
                    style={{
                      color: "#2B4A2A",
                      textDecorationLine: "line-through",
                      marginLeft: "0.5rem",
                    }}
                  >
                    Rs: 109.00
                  </Card.Text>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ border: "0" }}>
              <Card.Body style={{ padding: "0", textAlign: "right" }}>
                <Card.Text style={{ color: "#5A065E", fontWeight: "700" }}>
                  You Save Rs 10.90
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ paddingTop: "1.3rem" }}>
          <Col>
            <Button
              style={{
                backgroundColor: "#5A065E",
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: "700",
                border:"0"
              }}
            >
              Add to Cart
            </Button>
          </Col>
        </Row>
        <Row style={{ paddingTop: "1.3rem" }}>
          <Col>
            <Card style={{ border: "0" }}>
              <Card.Title
                style={{
                  fontSize: "2.5rem",
                  color: "#2B4A2A",
                  fontWeight: "700",
                }}
              >
                Herbal Salve
              </Card.Title>
              <Card.Body style={{ padding: "0", textAlign: "right" }}>
                <Card.Text style={{ color: "#2B4A2A",width:"100%",textAlign:"justify"}}>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites of the
                  word in classical literature, discovered the undoubtable
                  source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
                  "de Finibus Bonorum et Malorum" (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a treatise on
                  the theory of ethics, very popular during the Renaissance. The
                  first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
                  comes from a line in section 1.10.32. The standard chunk of
                  Lorem Ipsum used since the 1500s is reproduced below for those
                  interested. Sections 1.10.32 and 1.10.33 from "de Finibus
                  Bonorum et Malorum" by Cicero are also reproduced in their
                  exact original form, accompanied by English versions from the
                  1914 translation by H. Rackham.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
