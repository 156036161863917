import React, { useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import logo from "../../../image/a648d3f55dd077e61140092e4ac296d6.png";
import img1 from "../../../image/IMG_4101.png";
import img2 from "../../../image/IMG_4093.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCaretDown,
  faSquareCaretUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

export default function CartPage() {
  const [count, setCount] = useState(0);
  const incrementCount = () => {
    setCount(count + 1);
  };
  const DecrementCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  return (
    <>
      <div style={{ width: "100%", position: "relative" }}>
        <Card
          style={{
            backgroundColor: "#5A065E",
            borderRadius: "0 0 3rem 3rem",
            padding: "1.5rem 0 5rem 0",
          }}
        >
          <Card style={{ margin: "0 Auto", padding: "0.5rem 1.5rem" }}>
            <img src={logo} alt="" style={{ maxHeight: "64px" }} />
          </Card>
          <Card.Title
            style={{
              fontSize: "2.4rem",
              color: "#FFFFFF",
              position: "absolute",
              bottom: "-3%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            My Cart
          </Card.Title>
        </Card>
      </div>
      <Container style={{ marginTop: "1rem" }}>
        <Card style={{ width: "100%" }}>
          <Card.Body style={{ display: "flex" }}>
            <Card.Img src={img1} style={{ width: "5rem", height: "5rem" }} />
            <Card.Body style={{ padding: "0 0 0 1rem" }}>
              <Card.Text
                style={{
                  textAlign: "right",
                  margin: "0",
                  position: "absolute",
                  right: "1rem",
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Card.Text>
              <Card.Title
                style={{
                  textAlign: "left",
                  marginBottom: "0.25rem",
                  color: "#2B4A2A",
                  fontWeight: "700",
                }}
              >
                Face Wash
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "left",
                  marginBottom: "0.25rem",
                  color: "#2B4A2A",
                  fontWeight: "400",
                }}
              >
                70 gms
              </Card.Text>
              <Card.Text
                style={{
                  color: "#2B4A2A",
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div>Quantity</div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      type="number"
                      value={count}
                      style={{ width: "60%", marginLeft: "0.8rem" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FontAwesomeIcon
                        icon={faSquareCaretUp}
                        onClick={incrementCount}
                      />
                      <FontAwesomeIcon
                        icon={faSquareCaretDown}
                        onClick={DecrementCount}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    color: "#2B4A2A",
                    fontWeight: "400",
                    fontSize: "1.5rem",
                  }}
                >
                  340.00
                </div>
              </Card.Text>
            </Card.Body>
          </Card.Body>
        </Card>
      </Container>
      <Container style={{ marginTop: "1rem" }}>
        <Card style={{ width: "100%" }}>
          <Card.Body style={{ display: "flex" }}>
            <Card.Img src={img2} style={{ width: "5rem", height: "5rem" }} />
            <Card.Body style={{ padding: "0 0 0 1rem" }}>
              <Card.Text
                style={{
                  textAlign: "right",
                  margin: "0",
                  position: "absolute",
                  right: "1rem",
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </Card.Text>
              <Card.Title
                style={{
                  textAlign: "left",
                  marginBottom: "0.25rem",
                  color: "#2B4A2A",
                  fontWeight: "700",
                }}
              >
                Hair Oil
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "left",
                  marginBottom: "0.25rem",
                  color: "#2B4A2A",
                  fontWeight: "400",
                }}
              >
                100 ml
              </Card.Text>
              <Card.Text
                style={{
                  color: "#2B4A2A",
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div>Quantity</div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      type="number"
                      value={count}
                      style={{ width: "60%", marginLeft: "0.8rem" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FontAwesomeIcon
                        icon={faSquareCaretUp}
                        onClick={incrementCount}
                      />
                      <FontAwesomeIcon
                        icon={faSquareCaretDown}
                        onClick={DecrementCount}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "right",
                    color: "#2B4A2A",
                    fontWeight: "400",
                    fontSize: "1.5rem",
                  }}
                >
                  210.00
                </div>
              </Card.Text>
            </Card.Body>
          </Card.Body>
        </Card>
        <Card style={{ width: "100%", border: "0"}}>
          <Card.Body style={{display:"flex",justifyContent:"end",padding:"1rem 0 0 0"}}>
            <Card.Text style={{marginRight:"2rem",color:"#2B4A2A",fontWeight:"700"}}>Shipping:</Card.Text>
            <Card.Text style={{color:"#2B4A2A",fontWeight:"700"}}>+0.00</Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "100%", border: "0"}}>
          <Card.Body style={{display:"flex",justifyContent:"end",padding:"0"}}>
            <Card.Text style={{marginRight:"2rem",color:"#2B4A2A",fontWeight:"700"}}>Available Points:</Card.Text>
            <Card.Text style={{color:"#2B4A2A",fontWeight:"700"}}>-50.00</Card.Text>
          </Card.Body>
        </Card>
        <Card style={{ width: "100%", border: "0"}}>
          <Card.Body style={{display:"flex",justifyContent:"end",padding:"0 0 1.5rem 0"}}>
            <Card.Text style={{color:"#2B4A2A",fontWeight:"700",fontSize:"1.5rem"}}>Rs 550.00</Card.Text>
          </Card.Body>
        </Card>
        <Button className="btn-shopping">Continue Shopping</Button>
        <Button className="btn-checkout">Check Out</Button>
      </Container>
    </>
  );
}
