import React from "react";
import { Card, Carousel } from "react-bootstrap";
import logo from "../../../image/a648d3f55dd077e61140092e4ac296d6.png";
import img1 from "../../../image/pexels-anna-shvets-5217926 1.png";
import HomeSec1 from "./HomeSec1";

export default function HomePage() {

  return (
    <>
      <div style={{width:"100%"}}>
        <Card
          style={{
            backgroundColor: "#5A065E",
            borderRadius: "0 0 3rem 3rem",
            padding: "1.5rem 0 5rem 0",
          }}
        >
          <Card style={{ margin: "0 Auto", padding: "0.5rem 1.5rem" }}>
            <img src={logo} alt="" style={{ maxHeight: "64px" }} />
          </Card>
        </Card>
        <Card className="carousel-card">
          <Carousel className="carousel-slide" controls={false}>
            <Carousel.Item>
              <img
                className="d-block w-100 slider-image"
                src={img1}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt="Third slide" />
            </Carousel.Item>
          </Carousel>
        </Card>
      </div>
      <HomeSec1 />
    </>
  );
}
