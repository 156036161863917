import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from 'react-router-dom';
import MainPage from "./components/webpage/MainPage";

// import PWA from "./components/PWA";
import Facebook from "./components/Facebook";

function App() {
  return (
    <>
    <div className="App" >
      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
    </div>
      {/* <PWA /> */}
      {/* <Facebook />  */}
    </>
  );
}

export default App;
