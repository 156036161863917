import React from 'react'
import Footer from './Footer'
import { Route, Routes } from "react-router-dom";
import HomePage from './homepage/HomePage'
import ProductPage from './productpage/ProductPage';
import CartPage from './CartPage/CartPage';

export default function MainPage() {
  return (
    <>
    <Routes>
        <Route path="*" element={<HomePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/cart" element={<CartPage />} />
    </Routes>
    <Footer style={{sticky:"bottom"}}/>
    </>
  )
}

