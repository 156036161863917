import React, { useEffect, useState } from "react";
import axios from 'axios'
import { Card, Row, Col, Container } from "react-bootstrap";
import img1 from "../../../image/ef16cb337e7207e1f957d2f5ee113e39.png";
import img2 from "../../../image/eb0d96c9007a793ebe0d5150148202f1.png";
import img3 from "../../../image/ca6fdacdb831991171123f1e0b501abc.png";
import img4 from "../../../image/69dc6478dd9e8be67e90bf04ca85dfab.png";
import img5 from "../../../image/e491a06ee4ec494c5846e79683c1544a.png";
import img6 from "../../../image/e491a06ee4ec494c5846e79683c1544a.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";


export default function HomeSec1() {
    const cardimage1 = [img1,img2,img3]
    const [data, setdata] = useState([])
    const cardtitle1 = ["Herbal","Inhaler","Hair Gel"]
    const cardtext1 = ["70 gms","5 ml","200 gms"]
    const rs1 = ["Rs: 170.00","Rs: 99.00","Rs: 320.00"]
    const cardimage2 = [img4,img5,img6]
    const cardtitle2 = ["Herbal Salve","Massage Oil","Massage Oil"]
    const cardtext2 = ["25 ml","50 ml","100 ml"]
    const rs2 = ["Rs: 190.00","Rs: 220.00","Rs: 320.00"]
    useEffect(()=>{
      axios.get('https://app.wondernaturals.in/api/user/allProducts')
      .then(x => { setdata(x.data) })
    },[])
    console.log(data)
  return (
    <>
      <Container>
        <div style={{paddingBottom:"1rem"}}>
        <Row xs={3} md={3} className="g-2">
          {Array.from({ length: 3 }).map((_, idx) => (
            <Col>
              <Card style={{padding : "0.5rem"}}>
                <div style={{padding : "0.5rem 0.5rem 0 0.5rem",display: "flex"}}>
                    <Card.Img variant="top" src={cardimage1[idx]} className="homesec1cardimg"/>
                    <FontAwesomeIcon icon={faHeart} style={{textAlign:"right"}}/>
                </div>
                <Card.Body style={{padding : "1rem 0rem 0 0"}}>
                  <Card.Title style={{textAlign : "start",color : "#5A065E",fontFamily: 'Work Sans',fontWeight: 700}}>{cardtitle1[idx]}</Card.Title>
                  <Card.Text style={{textAlign : "start",marginBottom : "var(--bs-card-title-spacer-y)",color : "#5A065E",fontFamily: 'Work Sans',fontWeight: 400}}>{cardtext1[idx]}</Card.Text>
                  <Card.Text style={{textAlign : "start",color : "#5A065E",fontFamily: 'Work Sans',fontWeight: 700,display:"flex",justifyContent: "space-between"}}>
                    {rs1[idx]}
                    <FontAwesomeIcon icon={faCartPlus} style={{textAlign:"right"}}/>
                    </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </div>
        <div>
        <Row xs={3} md={3} className="g-2">
          {Array.from({ length: 3 }).map((_, idx) => (
            <Col>
              <Card style={{padding : "0.5rem"}}>
                <div style={{padding : "0.5rem 0.5rem 0 0.5rem",display: "flex"}}>
                    <Card.Img variant="top" src={cardimage2[idx]} className="homesec1cardimg"/>
                    <FontAwesomeIcon icon={faHeart} style={{textAlign:"right"}}/>
                </div>
                <Card.Body style={{padding : "1rem 0rem 0 0"}}>
                  <Card.Title style={{textAlign : "start",color : "#5A065E",fontFamily: 'Work Sans',fontWeight: 700}}>{cardtitle2[idx]}</Card.Title>
                  <Card.Text style={{textAlign : "start",marginBottom : "var(--bs-card-title-spacer-y)",color : "#5A065E",fontFamily: 'Work Sans',fontWeight: 400}}>{cardtext2[idx]}</Card.Text>
                  <Card.Text style={{textAlign : "start",color : "#5A065E",fontFamily: 'Work Sans',fontWeight: 700,display:"flex",justifyContent: "space-between"}}>
                    {rs2[idx]}
                    <FontAwesomeIcon icon={faCartPlus} style={{textAlign:"right"}}/>
                    </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        </div>
      </Container>
    </>
  );
}
